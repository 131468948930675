<template>
  <div class="kjDetails">
    <!-- <img class="body-text-img" src="../../assets/home/315@2x.png" alt /> -->
    <div class="top">
      <img class="kj-img" src="../assets/detail/kj.png" alt="" />
      <div class="kj-name">
        <span class="left" style="font-weight: bolder;">
          {{ routerParams.name }}
        </span>
        <span class="right" style="font-weight: bolder;">
          {{ routerParams.price }}
        </span>
      </div>
      <img src="../assets/detail/yjbh.png" alt="" class="kj-img2" />
      <div class="kj-nh" style="font-weight: bolder;">
        单位算力耗能仅为{{ routerParams.minbuyCalculation }}
      </div>
      <div class="kj-reason">
        <div>
          <img class="zan" src="../assets/detail/zan.png" alt="" />
          <img class="tjly" src="../assets/detail/tjly.png" alt="" />
        </div>
        <div style="margin-top:15px;">
          {{ routerParams.locate }}
        </div>
      </div>
    </div>
    <img style="margin: 10px 0" src="../assets/detail/rxhd.png" alt="" />
    <van-collapse
      v-model="activeNames"
      style="font-weight: bolder;color: #333333;"
    >
      <van-collapse-item title="产品详情" name="1">
        <div class="body-bg">
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿机型号</span>
            <span class="body-text-right"
              >{{ routerParams.typeName }}{{ routerParams.batchNum }}</span
            >
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">矿机产出</span>
            <span class="body-text-right">BTC</span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿机型号</span>
            <span class="body-text-right"
              >{{ routerParams.typeName }}{{ routerParams.batchNum }}</span
            >
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">算力</span>
            <span class="body-text-right">{{
              routerParams.calculationPower
            }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">矿机功耗</span>
            <span class="body-text-right">{{ routerParams.powerCost }}</span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿场位置</span>
            <span class="body-text-right">{{ routerParams.locate }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">每日电费</span>
            <span class="body-text-right">{{
              routerParams.electricityCost
            }}</span>
          </div>
          <div class="body-text-line"></div>
          <div class="body-text-body">
            <span class="body-text-left">矿机价格</span>
            <span class="body-text-right">{{ routerParams.price }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">质保费用</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">预估单日产出</span>
            <span class="body-text-right">{{ routerParams.locatePrice }}</span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">预估单日收益</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">参考关机币价</span>
            <span class="body-text-right">{{ routerParams.preElectric }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">电费</span>
            <span class="body-text-right">{{
              routerParams.electricityCost
            }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">平台管理费</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">托管服务费分成</span>
            <span class="body-text-right">每日产出20%</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">24K钱包打币</span>
            <span class="body-text-right">付款15天后开始产出</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">矿池打币</span>
            <span class="body-text-right">付款45天后开始产出</span>
          </div>
        </div></van-collapse-item
      >
      <van-collapse-item title="扣费说明" name="2">
        1、下单时，需支付矿机费用、物流清关费及上下
        架费用，以支付页面显示金额为准。
        2、矿机首次开机运行时，开机日扣除本月应缴平
        台管理费及电费，对应平台管理费及电费扣费计算 方式为:
        ①平台管理费(按月缴纳，当月应缴天数D)平台管 理费=月度管理费*折算系数*台数
        ②电费(以当月剩余日期D为例)，电费=电费单价*矿
        机功率/1000*24*D*(1+线损率)*台数，线损率通常 为5%左右
        3、完成首次扣费后，每个月26号1:00(UTC+8)从
        您的点卡账户扣除下月整月的电费与平台管理费，
        若开机日在26号之后，则于开机日缴纳本月费用后， 再次缴纳下月费用。
        4、若因您的账户余额不足等因素，导致电费或平
        台管理费扣款失败，则矿机会进入关停状态，您
        会无法获取挖矿收益。24K平台系统自客户欠费
        停机起第二日，会进入自动补缴状态，每日尝试为
        欠费用户进行一次自动补缴，若补缴扣款成功则会
        在次日恢复矿机运行状态，保障客户产出收益。</van-collapse-item
      >
      <van-collapse-item title="购买流程" name="3">购买流程</van-collapse-item>
      <van-collapse-item title="收益说明" name="4">收益说明</van-collapse-item>
      <van-collapse-item title="更多常见问题" name="5"
        >更多常见问题</van-collapse-item
      >
    </van-collapse>
    <div class="kj-sm">
      <div class="footer-title">免责声明：</div>
      <div class="footer-body">
        1、按照约定，一旦您购买完成，将不支持更换或退款。
        2、不对以下不可控风险所造成的损失负责:不能预见、
        不能避免或不能克服的客观事件，包括自然灾害如洪水、
        火山爆发、地震、山崩、火灾、被政府部门评定为百年
        不遇级别以上的风暴和恶劣气候等，政府行为和政府指
        令，城市级别的电网供电事故，以及社会异常事件如战 争、罢工、动乱等。
        3、24K保留对本套餐的最终解释权。
      </div>
    </div>
    <!-- <div class="footer-body" v-html="routerParams.productExplain"></div> -->
  </div>
</template>

<script>
export default {
  name: "kjDetails",
  data() {
    return {
      activeNames: ["1"],
      num: 0,
      routerParams: {},
      id: "",
    }
  },
  created() {},
  methods: {
    getHomeList() {
      this.$request
        .post("/kjInfo/getKjInfo", { id: this.id })
        .then(
          (res) => {
            if (res.code == 200) {
              this.routerParams = res.data
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
  mounted() {
    this.id = this.$route.query.id
    this.getHomeList()
    // this.routerParams = JSON.parse(this.$route.params.itemObj);
    // console.log(this.routerParams);
  },
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.kjDetails {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  overflow: auto;
  padding: 14px 20px 20px;
  text-align: left;
  border-radius: 12px;
  padding: 20px 15px;
  .top {
    height: 480px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    .kj-img {
      height: 160px;
      margin-top: 20px;
    }
    .kj-name {
      width: 100%;
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      line-height: 25px;
      .left {
        font-size: 18px;
        color: #4a4a4a;
      }
      .right {
        font-size: 18px;
        color: #f8b34d;
      }
    }
    .kj-img2 {
      margin-top: 8px;
      width: 100%;
    }
    .kj-nh {
      width: 100%;
      margin-top: 16px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
      text-align: left;
    }
    .kj-reason {
      margin-top: 20px;
      width: 100%;
      height: 124px;
      background: rgba(248, 222, 77, 0.12);
      border-radius: 8px;
      padding: 16px 7px 0 12px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: left;
      .zan {
        width: 20px;
        margin-right: 8px;
      }
      .tjly {
        width: 68px;
      }
    }
  }
  .body-bg {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    padding: 20px 16px;
    background: #fff;
    border-radius: 12px;
    .body-text-img {
      margin-bottom: 20px;
    }
    .body-text-line {
      height: 1px;
      background: #f3f3f3;
      margin-bottom: 20px;
    }
    .body-text-body {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      line-height: 18px;
      .body-text-left {
        color: #93a5b2;
      }
      .body-text-right {
        color: #4a4a4a;
      }
    }
    .body-text-body:last-child {
      margin-bottom: 0px;
    }
  }
  .kj-sm {
    width: 100%;
    margin-top: 10px;
    padding: 16px 16px 0;
    background: #ffffff;
    height: 250px;
    border-radius: 8px;
    color: #333333;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;

    .footer-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 8px;
      font-size: 14px;
      color: #ff3500;
    }
    .footer-body {
      width: 100%;
      font-size: 13px;
      line-height: 25px;
      letter-spacing: 1px;
      font-size: 13px;
    }
  }
}
.m-b-5 {
  margin-bottom: 5px;
}
</style>
<style lang="scss">
.van-tabs__nav {
  background: #f8f8f8;
}
.van-tabs__line {
  background-color: #1677ff;
}
.van-collapse-item__content {
  font-weight: normal;
  color: #333333;
}
</style>
